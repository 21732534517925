import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import moment from "moment"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

export const fetchObservationRubric = createAsyncThunk(
  "activeEventSlice/fetchObservationRubric",
  async (args, thunkAPI) => {
    const { userRubricId } = args
    /**
     * Represents MobileMind User Rubric Details Resource records as resources.
     *
     * * /api/user_rubric_details/{rubric_id}
     * * * rubric_id = [int] (internal drupal id of user rubric id)
     */

    let rubricResponse = await fetchWrapper.get(
      "/api/user_rubric_details/" + userRubricId
    )

    if (rubricResponse.ok) {
      const data = await rubricResponse.json()
      return data.rubric_data
    }
  }
)

export const fetchActiveEvent = createAsyncThunk(
  "activeEventSlice/fetchActiveEvent",
  async (args, thunkAPI) => {
    const { isConference, isObservation, id } = args
    let event, room
    let query = {
      filter: {
        drupal_internal__id: id,
      },
      include:
        "field_tags,field_location,field_personnel,field_event_image,field_attachment,field_room,field_event_category",
    }

    let bundle = isConference ? "conference" : "event_base"
    if (isObservation) {
      bundle = "observation"
    }
    if (isConference) {
      query.include += ",field_event,field_rooms"
    }

    let response = await fetchWrapper.get(
      "/api/mobilemind_event_entity/" + bundle + "?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()
      let addOn, roomsInOrder

      let rooms =
        data.included &&
        data.included.filter(
          (included) => included.type === "mobile_mind_room--room_base"
        )

      if (isConference) {
        rooms.forEach((room) => {
          room.text = room.attributes.field_room_number_name
        })

        let orderedRoomIds = data.data[0].relationships.field_rooms.data.map(
          (room) => room.id
        )

        let filteredRooms = rooms.filter(
          (room) => !room.attributes.field_archive
        )

        // Order the rooms the way they are on the entity
        roomsInOrder = orderedRoomIds
          .map((roomId) => {
            return filteredRooms.find((room) => room.id === roomId)
          })
          .filter((room) => room)
      } else {
        room = rooms && rooms[0]
      }

      // This might be a conference event
      if (!data.data.length) {
        query.include =
          "field_tags,field_location,field_personnel,field_event_image,field_attachment,field_room,field_event_category"

        response = await fetchWrapper.get(
          "/api/mobilemind_event_entity/conference_event?" + qs.stringify(query)
        )

        if (response.ok) {
          data = await response.json()
        }
      }

      let personnel =
        data.included &&
        data.included.filter(
          (included) =>
            included.type === "mm_event_personnel--mm_event_personnel"
        )

      room =
        bundle !== "conference " &&
        data.included &&
        data.included.find(
          (included) => included.type === "mobile_mind_room--room_base"
        )

      const tags =
        data.included &&
        data.included.filter(
          (included) => included.type === "taxonomy_term--tags"
        )
      data.tags = tags ? tags : []

      query = {
        include:
          "field_badge,field_prereq_courses,field_prereq_learning_path,field_participants_users,field_participants_group,field_participants_job_title,field_replacement_courses",
      }

      let addOnResponse = await fetchWrapper.get(
        "/api/mobilemind_event_addon/event_addon_base/" +
          data.data[0].relationships.field_addon.data.id +
          "?" +
          qs.stringify(query)
      )

      if (addOnResponse.ok) {
        addOn = await addOnResponse.json()
      }

      let log,
        badge,
        logId =
          addOn.data.relationships.field_attendance_log.data &&
          addOn.data.relationships.field_attendance_log.data.id

      if (logId) {
        let logResponse = await fetchWrapper.get(
          "/api/mm_attendance_log/attendance_log_base/" + logId
        )
        if (logResponse.ok) {
          log = await logResponse.json()
        }
      }

      let RSVPTotals = await fetchWrapper.get("/api/event_attendance/" + id)
      let RSVPData = await RSVPTotals.json()

      if (addOn.data.relationships.field_badge.data) {
        let badgeRequest = await fetchWrapper.get(
          "/api/badges_entity/badges_entity/" +
            addOn.data.relationships.field_badge.data.id +
            "?include=field_badge_image"
        )
        if (badgeRequest.ok) {
          badge = await badgeRequest.json()
        }
      }

      const attendance = await fetchWrapper.get("/api/event_attendance/" + id)
      let totalAttendees = 0
      if (attendance.ok) {
        let attendanceData = await attendance.json()
        totalAttendees = Number(attendanceData.attendance_data.accept_events)
      }

      event = data

      const feedbackFormId =
        addOn.data.relationships.field_feedback_form.data?.id
      if (feedbackFormId) {
        let formResponse = await fetchWrapper.get(
          "/api/mm_form/event_feedback/" +
            feedbackFormId +
            "?include=field_questions"
        )

        if (formResponse.ok) {
          formResponse = await formResponse.json()

          if (formResponse.included) {
            formResponse.data.questions = formResponse.included
            formResponse.data.questions.forEach((question) => {
              if (!question.userResponse && question.userResponse !== 0) {
                question.userResponse = ""
              }
            })
            event.feedbackForm = formResponse.data
          }
        }
      }

      event.room = room
      event.personnel = personnel
      event.totalAttendees = Number(RSVPData.attendance_data.accept_events)
      event.badge = badge
      event.attendanceLog = log
      event.addOn = addOn
      event.totalAttendees = totalAttendees
      event.availableRooms = roomsInOrder ? roomsInOrder : []

      event.isConference = isConference
    }
    return event
  }
)

export const fetchConferenceSessions = createAsyncThunk(
  "activeEventSlice/fetchConferenceSessions",
  async (args, thunkAPI) => {
    // After the endpoint work is done, we'll do this
    /**
     * Represents MobileMind Conference Explore API as resources.
     *
     * @RestResource (
     *   id = "mm_conf_explore",
     *   label = @Translation("MobileMind Conference Explore API"),
     *   uri_paths = {
     *     "canonical" = "/api/conf_explore/{conf_id}",
     *   }
     * )
     *
     * This plugin returns event attendance data for authenticated users
     * * * /api/conf_explore/{conf_id}?
     * * * &start = [str]
     * * * &end = [str]
     * * * &sort_order= [str (ASC, DESC)] (sorts by newest to oldest event. default ASC.)
     */

    let response = await fetchWrapper.get("/api/conf_explore/" + args.id)

    if (response.ok) {
      let data = await response.json()
      return data.data
    }
  }
)

export const fetchConferenceEventUserEvent = createAsyncThunk(
  "activeEventSlice/fetchConferenceEventUserEvent",
  async (args, thunkAPI) => {
    let query = {
      filter: {
        "field_user.id": thunkAPI.getState().session.user.id,
        "field_event.id": args.id,
      },
    }

    let response = await fetchWrapper.get(
      "/api/mobile_mind_user_event/conference_event?" + qs.stringify(query)
    )
    if (response.ok) {
      let data = await response.json()
      return data.data[0]
    }
  }
)

export const fetchConference = createAsyncThunk(
  "activeEventSlice/fetchConference",
  async (args, thunkAPI) => {
    let query = {
      filter: {
        drupal_internal__id: args.id,
      },
    }

    let response = await fetchWrapper.get(
      "/api/mobilemind_event_entity/conference?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      return data.data[0]
    }
  }
)

export const fetchEventMessages = createAsyncThunk(
  "activeEventSlice/fetchEventMessages",
  async (args, thunkAPI) => {
    let query = {
      filter: {
        "field_event.id": thunkAPI.getState().activeEvent.id,
      },
      include: "user_id, field_attachment",
    }

    let response = await fetchWrapper.get(
      "/api/mm_event_message/event_message_base?" + qs.stringify(query)
    )
    if (response.ok) {
      let data = await response.json()

      const users =
        data.included &&
        data.included.filter((included) => included.type === "user--user")

      data.data.forEach((message) => {
        message.user = users.find(
          (user) =>
            message.relationships.user_id.data &&
            user.id === message.relationships.user_id.data.id
        )
      })

      return data.data
    }
  }
)

export const fetchEventAttendees = createAsyncThunk(
  "activeEventSlice/fetchEventAttendees",
  async (args, thunkAPI) => {
    let searchQuery = thunkAPI.getState().activeEvent.attendeeSearch

    let query = {
      search: searchQuery,
    }

    if (args && args.field_rsvp_value) {
      query.field_rsvp_value = args.field_rsvp_value
    }

    let eventId = thunkAPI.getState().activeEvent.drupal_internal__id

    let response = await fetchWrapper.get(
      "/api/user-events/" +
        eventId +
        "/" +
        thunkAPI.getState().session.group.id[0].value +
        "?" +
        qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      if (!searchQuery) {
        data.rows.slice(0, 10)
      }
      return data
    }
  }
)

export const checkIn = createAsyncThunk(
  "activeEventSlice/checkIn",
  async (args, thunkAPI) => {
    let eventId, submittedJoinCode

    const { activeEvent, session } = thunkAPI.getState()
    const { userEvent } = args

    if (args && args.isSession) {
      submittedJoinCode = args.joinCode
      eventId = args.drupal_internal__id
    } else {
      eventId = activeEvent.drupal_internal__id
      submittedJoinCode = activeEvent.joinCode
    }

    let eventUUID = activeEvent.id
    let userEventResponse

    // Before we do anything, if they don't have a user event, we need to create one
    if (!userEvent) {
      // Sort out all our weirdly bundled IDs and stuff
      let bundle =
        activeEvent.bundle === "event_base"
          ? "user_event_base"
          : activeEvent.bundle
      let eventBundle = activeEvent.bundle

      if (activeEvent.activeSession?.id) {
        bundle = "conference_event"
        eventBundle = "conference_event"
        eventId = activeEvent.activeSession.id
        eventUUID = activeEvent.conferenceSessions.data.find(
          (conf_event) => conf_event.session_id === eventId
        )?.session_uuid
      }

      const body = {
        data: {
          type: "mobile_mind_user_event--" + bundle,
          attributes: {
            name:
              "UID: " +
              thunkAPI.getState().session.user.attributes.drupal_internal__uid +
              " / Event: " +
              eventId,
          },
          relationships: {
            field_user: {
              data: {
                type: "user--user",
                id: thunkAPI.getState().session.user.id,
              },
            },
            field_event: {
              data: {
                type: "mobilemind_event_entity--" + eventBundle,
                id: eventUUID,
              },
            },
          },
        },
      }

      // If this is a session for a conference,
      // we'll need to attach that parent event as well
      if (activeEvent.activeSession?.id || activeEvent.parent?.id) {
        body.data.relationships.field_parent_event = {
          data: {
            type: "mobilemind_event_entity--conference",
            id: activeEvent.parent?.id ?? activeEvent.id,
          },
        }
      }

      userEventResponse = await fetchWrapper.post(
        "/api/mobile_mind_user_event/" + bundle,
        session.token,
        JSON.stringify(body)
      )
    }

    let response = await fetchWrapper.get(
      "/api/attendance-log/" + eventId + "/" + submittedJoinCode
    )
    if (response.ok) {
      let data = await response.json()
      let userEventData =
        userEventResponse?.ok && (await userEventResponse.json())
      return { data, userEventData }
    }
  }
)

const debouncedFetchAttendees = debounceThunk(fetchEventAttendees, 750)

export const updateAttendeeSearch = createAsyncThunk(
  "activeEventSlice/updateAttendeeSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedFetchAttendees())
    return args
  }
)

const today = new Date()
const endDate = new Date(today)

endDate.setDate(endDate.getDate() + 1)

export const activeEventSlice = createSlice({
  name: "activeEventSlice",
  initialState: {
    atCapacity: false,
    tags: [],
    fetched: false,
    currentTab: window.location.href.includes("/conference")
      ? "schedule"
      : "details",
    isGoogleConfirmOpen: false,
    attachments: [],
    messages: [],
    attendeeSearch: "",
    personnel: [],
    joinCode: "",
    hasRSVPd: false,
    isCheckedIn: false,
    registrationWindow: "unlimited",
    helpfulLinks: "",
    conferenceLocations: [],
    parent: null,
    preRequisiteLP: null,
    isFullScreen: false,
    totalAttendees: 0,
    feedbackForm: null,
    addOn: null,
    observationRubric: null,
    hasRubric: false,
    conferenceSessions: {
      data: [],
      fetched: false,
    },
    scheduleFilters: {
      searchQuery: "",
      onlyMy: false,
      roomId: "any",
      locationId: null,
      tags: [],
      category: null,
    },
    activeSession: null,
  },
  reducers: {
    atCapacity: (state) => {
      state.atCapacity = true
    },
    clearObservationRubric: (state) => {
      state.observationRubric = null
    },
    setFeedbackFormResponse: (state, action) => {
      const { id, response } = action.payload

      const targetQuestion = state.feedbackForm.questions.find(
        (question) => question.id === id
      )
      targetQuestion.userResponse = response
    },
    changeEventTab: (state, action) => {
      state.currentTab = action.payload
    },
    setActiveSession: (state, action) => {
      state.activeSession = action.payload
    },
    updateScheduleFilters: (state, action) => {
      const { field, value, method } = action.payload

      if (field !== "tags") {
        state.scheduleFilters[field] = value
        if (field === "onlyMy" && !value) {
          state.scheduleFilters.roomId = "any"
        }
      } else {
        if (method === "reset") {
          state.scheduleFilters.tags = []
        } else if (method === "add") {
          state.scheduleFilters.tags.push(value)
        } else {
          state.scheduleFilters.tags.splice(value, 1)
        }
      }
    },
    updateField: (state, action) => {
      if (
        action.payload.field === "sendReminders" &&
        action.payload.value.includes("none")
      ) {
        if (
          action.payload.value.length &&
          !state.sendReminders.includes("none")
        ) {
          state.sendReminders = ["none"]
        } else {
          action.payload.value.shift()
          state.sendReminders = action.payload.value
        }
      } else {
        state[action.payload.field] = action.payload.value
      }
    },
    getAllAttendees: (state, action) => {
      state.allAttendees = action.payload
    },
    updateRSVP: (state, action) => {
      state.hasRSVPd = true
    },
    updateSessionRSVP: (state, action) => {
      const { appointmentData, rsvp } = action.payload
      state.conferenceSessions.data.forEach((session) => {
        if (session.session_id === appointmentData.id) {
          session.rsvp_status = rsvp
        }
      })
    },
    RSVPAtCapacity: (state, action) => {
      state.totalAttendees = action.payload
    },
  },
  extraReducers: {
    [checkIn.fulfilled]: (state, action) => {
      const { data, userEventData } = action.payload

      if (action.meta.arg && action.meta.arg.isSession) {
        state.conferenceSessions.data.forEach((confSession) => {
          if (data.success) {
            let eventId = confSession.session_id
            if (
              Number(eventId) === Number(action.meta.arg.drupal_internal__id)
            ) {
              confSession.attended = "1"
            }
          }
        })
      } else {
        if (data && data.success) {
          state.showCheckInWarning = false
          state.isCheckedIn = true
        } else {
          state.showCheckInWarning = true
        }
      }

      if (userEventData) {
        state.createdUserEvent = userEventData.data
      }
    },
    [fetchObservationRubric.fulfilled]: (state, action) => {
      state.observationRubric = action.payload
    },
    [updateAttendeeSearch.fulfilled]: (state, action) => {
      state.attendeeSearch = action.payload.value
    },
    [fetchEventAttendees.pending]: (state) => {
      state.attendeesSearching = true
    },
    [fetchEventAttendees.fulfilled]: (state, action) => {
      state.attendees = !action.payload.rows.content
        ? action.payload.rows.filter((row) => row.field_rsvp === "Accept")
        : []
      state.attendeesSearching = false
    },
    [fetchEventMessages.fulfilled]: (state, action) => {
      state.messages = action.payload?.reverse()
    },
    [fetchConference.fulfilled]: (state, action) => {
      state.parent = action.payload
    },
    [fetchConferenceSessions.fulfilled]: (state, action) => {
      state.conferenceSessions.data = action.payload
      state.conferenceSessions.fetched = true
    },
    [fetchConferenceEventUserEvent.fulfilled]: (state, action) => {
      state.conferenceEventUserEvent = action.payload
    },
    [fetchActiveEvent.pending]: (state, action) => {
      if (!action.meta.arg.refresh) {
        state.conferenceLocations = []
        if (window.location.href.includes("/conference")) {
          state.currentTab = "schedule"
        } else {
          state.currentTab = "details"
        }
        state.fetched = false
      }
    },
    [fetchActiveEvent.fulfilled]: (state, action) => {
      state.hasRSVPd = false

      const addOn = action.payload.addOn
      state.addOn = addOn.data
      state.hasRubric = action.payload.data[0]?.relationships.field_rubric?.data
        ?.id
        ? true
        : false

      const attributes = action.payload.data[0].attributes
      state.totalAttendees = action.payload.totalAttendees

      state.feedbackForm = action.payload.feedbackForm

      if (
        action.payload.data[0].attributes.field_capacity > 0 &&
        state.totalAttendees < action.payload.data[0].attributes.field_capacity
      ) {
        state.atCapacity = false
      }
      state.sessionsLocked =
        action.payload.data[0].attributes.field_rsvp_to_view_sessions

      const includedCourses =
        addOn.included &&
        addOn.included.filter(
          (included) => included.type === "course_entity--course_entity"
        )
      const eventCategory =
        action.payload.included &&
        action.payload.included.find(
          (included) => included.type === "taxonomy_term--category"
        )

      const preRequisiteCourseIds =
        addOn.data.relationships.field_prereq_courses.data.map(
          (course) => course.id
        )
      const replacementCourseIds =
        addOn.data.relationships.field_replacement_courses.data.map(
          (course) => course.id
        )
      const badgeAwarded = action.payload.badge
      const images =
        action.payload.included &&
        action.payload.included.filter(
          (included) => included.type === "file--image"
        )

      state.preRequisiteLP =
        addOn.included &&
        addOn.included.find(
          (included) => included.type === "learning_path--learning_path"
        )

      state.isCheckedIn = false
      state.joinCode = ""

      state.tags = action.payload.tags

      state.bundle = action.payload.data[0].type.split("--")[1]
      state.eventCategory = eventCategory
      state.categoryOther = attributes.field_event_category_other
      state.allDay = attributes.field_all_day

      // Just bring in presenters
      let presenters = action.payload.personnel
        ? action.payload.personnel.filter(
            (personnel) =>
              personnel.attributes.field_event_role_name === "Presenter"
          )
        : []

      state.personnel = presenters.length ? presenters : []

      if (!action.meta.arg.refresh) {
        state.currentTab =
          action.payload.conferenceSessions &&
          action.payload.conferenceSessions.data.length
            ? "schedule"
            : "details"

        // See if there is more than one location
        const eventLocations =
          action.payload.included &&
          action.payload.included.filter(
            (included) =>
              included.type === "mobile_mind_location--location_base"
          )

        if (action.payload.isConference && eventLocations.length > 1) {
          state.conferenceLocations = eventLocations
          state.scheduleFilters.locationId = eventLocations[0].id
        } else {
          state.location = eventLocations && eventLocations[0]
        }
      }

      state.availableRooms = action.payload.availableRooms

      if (
        action.payload.data[0].attributes.field_type !== "In Person Live" &&
        action.payload.availableRooms &&
        action.payload.availableRooms.length
      ) {
        action.payload.availableRooms.unshift({
          id: "none",
          text: "Virtual Sessions",
          attributes: {
            name: "Virtual Sessions",
          },
        })
      }

      state.eventRoom = action.payload.room
      state.eventImage = {
        file:
          images &&
          images.find(
            (image) =>
              action.payload.data[0].relationships.field_event_image.data &&
              image.id ===
                action.payload.data[0].relationships.field_event_image.data.id
          ),
      }

      state.id = action.payload.data[0].id
      state.drupal_internal__id = attributes.drupal_internal__id
      state.name = attributes.name
      state.helpfulLinks = attributes.field_helpful_links
        ? attributes.field_helpful_links.value
        : ""

      state.supportContact = attributes.field_contact_info
        ? attributes.field_contact_info.value
        : ""

      state.startDate = moment(
        attributes.field_event_date_time[0].value
      ).format()
      state.endDate = moment(
        attributes.field_event_date_time[0].end_value
      ).format()

      state.description = attributes.field_description.value
        ? attributes.field_description.value
        : ""
      state.type = attributes.field_type

      state.notes =
        attributes.field_event_notes && attributes.field_event_notes.value
          ? attributes.field_event_notes.value
          : ""
      state.required = attributes.field_required ? "required" : "optional"

      state.meetingLink =
        attributes.field_meeting_link && attributes.field_meeting_link.uri

      state.attendanceMethod = addOn.data.attributes.field_attendance_method
        ? addOn.data.attributes.field_attendance_method
        : "manual"
      state.feedbackURL = addOn.data.attributes.field_feedback_form_url
        ? addOn.data.attributes.field_feedback_form_url
        : ""

      if (
        attributes.field_registration_start &&
        attributes.field_registration_end
      ) {
        state.registrationStarts = attributes.field_registration_start
        state.registrationEnds = attributes.field_registration_end
        state.registrationWindow = "dateRange"
      } else {
        state.registrationStarts = null
        state.registrationEnds = null
        state.registrationWindow = "unlimited"
      }

      state.difficulty = addOn.data.attributes.field_level
        ? addOn.data.attributes.field_level
        : "N/A"
      state.pdCredit = addOn.data.attributes.field_credit
        ? addOn.data.attributes.field_credit
        : 0
      state.preRequisiteCourses = includedCourses
        ? includedCourses.filter((course) =>
            preRequisiteCourseIds.includes(course.id)
          )
        : []
      state.replacementCourses = includedCourses
        ? includedCourses.filter((course) =>
            replacementCourseIds.includes(course.id)
          )
        : []
      state.badgeAwarded = badgeAwarded

      if (action.payload.attendanceLog) {
        state.attendanceLog = action.payload.attendanceLog.data
        state.checkedInAttendees =
          action.payload.attendanceLog.data.relationships.field_attendees.data
      }

      state.sessionMaxCapacity =
        action.payload.data[0].attributes.field_capacity

      // Handle attachments
      state.attachments = []
      if (action.payload.included) {
        action.payload.included.forEach((included) => {
          let isAttachment =
            action.payload.data[0].relationships.field_attachment.data &&
            action.payload.data[0].relationships.field_attachment.data.find(
              (attachment) => attachment.id === included.id
            )
          if (isAttachment) {
            state.attachments.push({
              id: included.id,
              filename: included.attributes.filename,
              extension: included.attributes.filename.split(".").pop(),
              file: included.attributes.uri.url,
            })
          }
        })
      }

      state.fetched = true
    },
  },
})

export const {
  changeEventTab,
  updateField,
  setFeedbackFormResponse,
  updateLocationField,
  removePresenter,
  addPresenter,
  setEventImage,
  removeEventImage,
  setNewEventAsActive,
  setEventImageFilename,
  setBadgeName,
  setActiveSession,
  setBadgeFilename,
  clearObservationRubric,
  updateScheduleFilters,
  setBadgeImage,
  setBadgeSaving,
  changeAttendees,
  addAttachment,
  removeAttachment,
} = activeEventSlice.actions

export default activeEventSlice.reducer
